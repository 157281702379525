const body = document.querySelector('body');
const header = document.querySelector('header');
let activeSelect, selectOptions, displaySelect; 

if(header && !header.classList.contains('main-header')) {
    activeSelect = header.querySelector('.property-filter .select-input .active-input');
    selectOptions = header.querySelector('.property-filter .select-input .select-options');
    displaySelect = header.querySelector('.display-filter');
}
const properties = document.querySelectorAll('.properties .tease-property');

if(body && body.classList.contains('home') && !header.classList.contains('main-header')) {
    displaySelect.querySelectorAll('a').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            if (!item.classList.contains('selected')) {
                displaySelect.querySelectorAll('a').forEach(link => {
                    link.classList.remove('selected');
                });
                item.classList.add('selected');
            }
        });
    });

    activeSelect.querySelectorAll('a').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            selectOptions.classList.add('anim');
            if (activeSelect.classList.contains('options-open')) {
                closeDropdown(this);
                body.classList.remove('no-scroll');
                item.classList.remove('open');
            }
            else {
                activeSelect.classList.add('options-open');
                activeSelect.classList.add('active');
                body.classList.add('no-scroll');
                item.classList.add('open');
            }
        });
    });

    selectOptions.querySelectorAll('a').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            let slug = item.getAttribute('data-slug');
            let shownProperties;
            let searchText = document.querySelector('#properties-search').value;
            if(document.querySelector('section.properties').classList.contains('list-display')) {
                properties.forEach(property => {
                    if(slug == 'alle') {
                        property.style.display = 'block';
                        property.classList.add('displayed');
                    }
                    else if(!property.classList.contains(slug)) {
                        property.style.display = 'none';
                        property.classList.remove('displayed');
                    }
                    else {
                        property.style.display = 'block';
                        property.classList.add('displayed');
                    }
                });
                if(searchText.length > 0) {
                    shownProperties = document.querySelectorAll(('.properties .tease-property.displayed'));
                    shownProperties.forEach((property) => {
                        let address = property.getAttribute('data-address');
                        property.style.display = 'none';
                        property.classList.remove('displayed');
                        if (address.toLowerCase().indexOf(searchText) !== -1) {
                            property.style.display = 'block';
                            property.classList.add('displayed');
                        }
                    });
                }
            }
            if(slug == 'kombinasjonsbygg' && window.innerWidth < 900) {
                activeSelect.querySelector('span').innerHTML = 'kombo';
            }
            else {
                activeSelect.querySelector('span').innerHTML = item.innerHTML;
            }
            selectOptions.querySelector('li.selected').classList.remove('selected');
            item.parentNode.classList.add('selected');
            if(window.innerWidth < 600 || (header.classList.contains('min-header') && window.innerWidth < 1400)) {
                activeSelect.querySelector('a.open').classList.remove('open');
            }
            closeDropdown(item);
        });
    });

    function closeDropdown() {
        selectOptions.classList.remove('anim');
        setTimeout(function () {
            document.querySelector('.property-filter .select-input').classList.remove('active');
            activeSelect.classList.remove('options-open');
        }, 500);
        body.classList.remove('no-scroll');
    }

    let filterOffset = header.querySelector('.property-filter').getBoundingClientRect().top;
    let filterHeight = header.querySelector('.property-filter').offsetHeight;
    let scrollHeight;
    if(window.innerWidth < 600) {
        scrollHeight = filterOffset - filterHeight - 60;
    } 
    else if(window.innerWidth < 900) {
        scrollHeight = filterOffset - filterHeight + 6;
    } 
    else if(window.innerWidth < 1400) {
        scrollHeight = 80;
    }
    else {
        scrollHeight = 90;
    }

    let propContainer = document.querySelector('section.properties');
    window.addEventListener('scroll', function (e) {
        if(window.scrollY > 5 && !header.classList.contains('animate')) {
            header.classList.add('animate');
        }
        if(window.innerWidth < 900) {
            if(scrollY >= (scrollHeight - 80)) {
                header.querySelector('.logo').classList.add('mini');
            }
            else {
                header.querySelector('.logo').classList.remove('mini');
            }
            if(scrollY >= scrollHeight) {
                document.querySelector('.filters').classList.add('sticky');
                header.classList.add('sticky');
                header.classList.add('min-header');
            }
            else {
                document.querySelector('.filters').classList.remove('sticky');
                header.classList.remove('sticky');
                header.classList.remove('min-header');
            }
        }
        else if(window.innerWidth < 1400) {
            if(scrollY > (scrollHeight - 32)) {
                header.querySelector('.logo').classList.add('mini');
            }
            else if((propContainer.classList.contains('list-display') || propContainer.classList.contains('map-display'))) {
                header.querySelector('.logo').classList.remove('mini');
            }
            if(scrollY > scrollHeight) {
                header.classList.add('min-header');
                document.querySelector('.filters').classList.add('sticky');
                header.classList.add('sticky');
            }
            else if((propContainer.classList.contains('list-display') || propContainer.classList.contains('map-display'))) {
                header.classList.remove('min-header');
                document.querySelector('.filters').classList.remove('sticky');
                header.classList.remove('sticky');
            }
            if((!(propContainer.classList.contains('list-display') || propContainer.classList.contains('map-display'))) && scrollY > 185) {
                header.classList.add('scrolledPast');
                document.querySelector('#content-section').style.marginTop = 0;
            }
        }
        else {
            if(scrollY > scrollHeight) {
                header.classList.add('min-header');
                header.querySelector('.logo').classList.add('mini');
            }
            else if((propContainer.classList.contains('list-display') || propContainer.classList.contains('map-display'))) {
                header.classList.remove('min-header');
                header.querySelector('.logo').classList.remove('mini');
            }
            if((!(propContainer.classList.contains('list-display') || propContainer.classList.contains('map-display'))) && scrollY > 170) {
                document.querySelector('#content-section').style.marginTop = 0;
                header.classList.add('scrolledPast');
            }
        }
    });
    window.addEventListener('resize', function(event) {
        if(window.innerWidth >= 900) {
            document.querySelector('.filters').style.paddingTop = 0;
        }
    });
}
else if(header && !header.classList.contains('main-header')) {
    header.classList.add('min-header');
}
else {
    if(header) {
        // Menu on scroll
        let headerHeight = header.offsetHeight;
        let lastScrollTop = 0;

        $(window).scroll(function (e) {
            let scrollTop = $(this).scrollTop();
                if (scrollTop <= 2) {
                    header.classList.remove('sticky', 'show');
                    setTimeout(function() {
                        header.classList.remove('animate');
                    }, 300);
                } 
                else if(scrollTop > headerHeight) {
                    if (scrollTop < lastScrollTop) { // Scrolling up
                        header.classList.add('sticky', 'animate', 'show')
                    } else {
                        header.classList.add('sticky');
                        header.classList.remove('show');
                    }
                }
            lastScrollTop = scrollTop;
        });

        const nav = header.querySelector('#nav-mobile');
        nav.addEventListener('click', (e) => {
            if(header.classList.contains('menu-open')) {
                header.classList.remove('anim');
                body.classList.remove('no-scroll');
                setTimeout(function() {
                    header.classList.remove('menu-open');
                }, 300);
            }
            else {
                header.classList.add('menu-open', 'anim');
                body.classList.add('no-scroll');
            }
        });
        window.addEventListener('scroll', function (e) {
            if(window.scrollY > 5 && !header.classList.contains('animate')) {
               
            }
        });
        window.addEventListener('resize', function(event) {
            if(window.innerWidth >= 900) {
                if(header.classList.contains('menu-open')) {
                    header.classList.remove('anim');
                    header.classList.remove('menu-open');
                    body.classList.remove('no-scroll');
                }
            }
        });
    }
}