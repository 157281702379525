import { intersectionObserve } from "./_lazy-image-video";
import { initMap } from "./_map";

const header = document.querySelector('header');
const footer = document.querySelector('footer');
const properties = document.querySelectorAll('.properties .list-properties .tease-property');
const displayFilter = document.querySelector('header .display-filter');
const propertySorting = document.querySelector('.properties .property-sorting');
let tileHeight, tilesContainer, tilesContainerHeight, propContainer, startPosX, startPosY;
let scrolledPastHeader = false;

function propertyEvents() {
    document.querySelectorAll('.properties .tiles .tease-property').forEach((item) => {
        let indicator = item.querySelector('.indicator-btn');
        let overlay = item.querySelector('.overlay');
        let videoContainer = item.querySelector('video')
        let videoURL = item.getAttribute('data-video');
        let isPlaying;
        item.addEventListener('mouseenter', (e) => {
            overlay.classList.add('show');
            if(indicator) {
                indicator.classList.add('show');
            }
            if(videoURL != "") {
                videoContainer.classList.add('show');
                videoContainer.src = videoURL;
                videoContainer.load();
                isPlaying = videoContainer.currentTime > 0 && !videoContainer.paused && !videoContainer.ended && videoContainer.readyState > videoContainer.HAVE_CURRENT_DATA;
                if (!isPlaying) {
                    videoContainer.play();
                }
            }
        });
        item.addEventListener('mouseleave', (e) => {
            overlay.classList.remove('show');
            if(indicator) {
                indicator.classList.remove('show');
            }
            if(videoURL != "") {
                videoContainer.classList.remove('show');
                videoContainer.pause();
                videoContainer.removeAttribute('src');
                videoContainer.load();
            }
        });
    });
    tilesContainer.querySelectorAll('.tile').forEach((tile) => {
        tile.querySelectorAll('.tease-property').forEach((property) => {
            if(property.offsetWidth < (window.innerWidth - 40)) {
                property.classList.add('square');
            }
            else {
                property.classList.add('landscape');
            }
        });
    });
}

if(properties.length > 0) {
    tileHeight = document.querySelectorAll('.properties .tiles .tile')[0].offsetHeight;
    tilesContainer = document.querySelector('.properties .tiles');
    tilesContainerHeight = tileHeight*3 + 40;
    startPosX = tilesContainerHeight/2 - tileHeight/6 - 13.33;
    startPosY = tileHeight + 20;
    tilesContainer.scrollLeft = startPosX;
    tilesContainer.scrollTop = startPosY;
    document.querySelector('.properties-wrapper').style.transition = 'opacity 0.3s ease';
    document.querySelector('.properties-wrapper').style.opacity = 1;
    propContainer = document.querySelector('section.properties');
    propertyEvents();

    if(window.innerWidth >= 900 && !(propContainer.classList.contains('list-display') || propContainer.classList.contains('map-display'))) {
        let wheelTimer;
        window.addEventListener("wheel", ({ deltaX, deltaY }) => {
            clearTimeout(wheelTimer);
            tilesContainer.classList.add("override-scroll");
            tilesContainer.scrollLeft += deltaX;
            tilesContainer.scrollTop += deltaY;
            wheelTimer = setTimeout(
              () => tilesContainer.classList.remove("override-scroll"),
              1000
            );
        });

        let timer = null;
        tilesContainer.addEventListener("scroll", (e) => {
            if(window.innerWidth < 1400 && header.classList.contains('scrolledPast')) {
                scrolledPastHeader = true;
            }
            else if(window.innerWidth >= 1400 && header.classList.contains('scrolledPast')) {
                scrolledPastHeader = true;
            }
            if(header.classList.contains('min-header') && scrolledPastHeader) {
               header.classList.add('hide');
            }
            if(timer !== null) {
                clearTimeout(timer);        
            }
            // Set a timeout to run after scrolling ends
            timer = setTimeout(function() {
                // Run the callback
                header.classList.remove('hide');
            }, 150);
        
           if(tilesContainer.scrollTop <= ((tilesContainerHeight/2) - (tileHeight*0.9)) && !tilesContainer.classList.contains('active')){
                // Add tile to top
                //console.log('Add tile to top');
                let tiles = document.querySelectorAll('.properties .tiles .tile');
                if(tiles != undefined) {
                    for(let i=1; i<4; i++) {
                        tilesContainer.prepend(tiles[tiles.length - i]);
                    }
                    tilesContainer.scrollTop += tileHeight;
                }
            }
            else if(tilesContainer.scrollTop > ((tilesContainerHeight/2) + (tileHeight*0.9)) && !tilesContainer.classList.contains('active')){
                // Add tile to bottom
                //console.log('Add tile to bottom');
                let tiles = document.querySelectorAll('.properties .tiles .tile');
                if(tiles != undefined) {
                    for(let i=0; i<3; i++) {
                        tilesContainer.appendChild(tiles[i]);
                    }
                    tilesContainer.scrollTop -= tileHeight;
                }
            }
            if(tilesContainer.scrollLeft >= ((tilesContainerHeight/2) + (tileHeight*0.9)) && !tilesContainer.classList.contains('active')){
                // Add tile to the right
                //console.log('Add tile to the right');
                let tiles = document.querySelectorAll('.properties .tiles .tile');
                if(tiles[3] != undefined) {
                    tiles[3].after(tiles[5]); // 3 settes inn etter 5
                    tilesContainer.scrollLeft -= tileHeight;
                }
            }
            else if(tilesContainer.scrollLeft < ((tilesContainerHeight/2) - (tileHeight*0.9)) && !tilesContainer.classList.contains('active')){
                // Add tile to the left
                //console.log('Add tile to the left');
                let tiles = document.querySelectorAll('.properties .tiles .tile');
                if(tiles[5] != undefined) {
                    tiles[5].before(tiles[3]); // 5 settes inn før 3
                    tilesContainer.scrollLeft += tileHeight;
                }
            }
        }, false);

        // Drag-scroll
        const preventClick = (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
        }
        let isDown = false;
        var isDragged = false;
        let startX;
        let startY;
        let scrollLeft;
        let scrollTop;
        let velX;
        let velY;
        tilesContainer.addEventListener('mousedown', (e) => {
            isDown = true;
            tilesContainer.classList.add('active');
            startX = e.pageX - tilesContainer.offsetLeft;
            scrollLeft = tilesContainer.scrollLeft;
            startY = e.pageY - tilesContainer.offsetTop;
            scrollTop = tilesContainer.scrollTop;
            cancelXMomentumTracking();
            cancelYMomentumTracking();
        });
        tilesContainer.addEventListener('mouseleave', () => {
            isDown = false;
            tilesContainer.classList.remove('active');
        });
        tilesContainer.addEventListener('mouseup', () => {
            isDown = false;
            const elements = document.querySelectorAll('section.properties .tiles article a');
            if(isDragged){
                for(let i = 0; i<elements.length; i++){
                    elements[i].addEventListener("click", preventClick);
                }
            }else{
                for(let i = 0; i<elements.length; i++){
                    elements[i].removeEventListener("click", preventClick);
                }
            }
            tilesContainer.classList.remove('active');
            isDragged = false;
            // Start a frame loop to continue drag momentum 
            beginXMomentumTracking();
            beginYMomentumTracking();
           
        });
        tilesContainer.addEventListener('mousemove', (e) => {
            if(!isDown) return;
            isDragged = true;
            e.preventDefault();
            const x = e.pageX - tilesContainer.offsetLeft;
            const y = e.pageY - tilesContainer.offsetTop;
            const walkX = (x - startX) * 1.5;
            const walkY = (y - startY) * 1.5;
                
            // Store the previous scroll positions
            var prevScrollLeft = tilesContainer.scrollLeft; 
            var prevScrollTop = tilesContainer.scrollTop; 
            tilesContainer.scrollLeft = scrollLeft - walkX;
            tilesContainer.scrollTop = scrollTop - walkY;
            
            // Compare change in position to work out drag speed 
            velX = tilesContainer.scrollLeft - prevScrollLeft; 
            velY = tilesContainer.scrollTop - prevScrollTop;
        });

        let xMomentumID;
        function beginXMomentumTracking(){
            cancelXMomentumTracking();
            xMomentumID = requestAnimationFrame(xMomentumLoop); 
        }
        function cancelXMomentumTracking(){
            cancelAnimationFrame(xMomentumID);
        }
        function xMomentumLoop(){
            if(Math.abs(velX) > 0.5) {
                tilesContainer.scrollLeft += velX; // Apply the velocity to the scroll position
                velX *= 0.9; // Slow the velocity slightly
                xMomentumID = requestAnimationFrame(xMomentumLoop); // Keep looping 
            }
        }

        let yMomentumID;
        function beginYMomentumTracking(){
            cancelYMomentumTracking();
            yMomentumID = requestAnimationFrame(yMomentumLoop); 
        }
        function cancelYMomentumTracking(){
            cancelAnimationFrame(yMomentumID);
        }
        function yMomentumLoop(){
            if(Math.abs(velY) > 0.5) {
                tilesContainer.scrollTop += velY; // Apply the velocity to the scroll position
                velY *= 0.9; // Slow the velocity slightly
                yMomentumID = requestAnimationFrame(yMomentumLoop); // Keep looping 
            }
        }
    }

    // Property metadata
    let newArray = [];
    let propArray = Array.from(properties);
    propArray.forEach((item) => {
        let propObject = item;
        let propAddress = item.getAttribute('data-address');
        let propAreal = item.getAttribute('data-areal');
        let propType = item.getAttribute('data-type');
        let propVacant = item.getAttribute('data-vacant');
        newArray.push({ propObject, propAddress, propAreal, propType, propVacant });
    });

    // Display filter click event
    const leftContent = document.querySelector('.properties .left-content');

    displayFilter.querySelectorAll('a').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            window.scrollTo(0,0);
            let slug = item.getAttribute('data-slug');
            displayFilter.querySelector('a.selected').classList.remove('selected');
            item.classList.add('selected');
            document.querySelector('section.properties').classList.remove('list-display');
            document.querySelector('section.properties').classList.remove('map-display');
            footer.style.display = 'none';
            header.querySelector('.logo').classList.remove('mini');
            setTimeout(function() {
                header.querySelector('.logo').classList.remove('anim');
            }, 400);
            if(slug == 'liste') {
                if(window.innerWidth >= 900) {
                    setTimeout(function() {
                        document.querySelector('#content-section').style.marginTop = 230 + 'px';
                        document.querySelector('#content-section').style.marginTop = header.offsetHeight + 'px';
                        header.classList.remove('scrolledPast');
                    }, 50);
                }
                if(window.innerWidth >= 1400) {
                    setTimeout(function() {
                        document.querySelector('#content-section').style.marginTop = 211 + 'px';
                    }, 55);
                }
                document.querySelector('.filters').classList.remove('sticky');
                header.classList.remove('sticky');
                header.classList.remove('min-header');

                setImage();
                document.querySelector('section.properties').classList.add('list-display');
                let selectedType = header.querySelector('.property-filter .select-input .select-options li.selected a').getAttribute('data-slug');
                if(selectedType != 'alle') {
                    properties.forEach((prop) => {
                        if(!prop.classList.contains(selectedType)) {
                            prop.classList.add('hide');
                        }
                    });
                }
                document.querySelector('.properties .property-sorting a.active').classList.remove('desc');
                document.querySelector('.properties .property-sorting a.active').classList.remove('asc');
                document.querySelector('.properties .property-sorting a.active').classList.remove('active');
                document.querySelector('.properties .property-sorting a.free-space').classList.add('active');
                document.querySelector('.properties .property-sorting a.free-space').classList.add('desc');
                let sortedArray = [...newArray].sort((a, b) => a['propVacant'] - b['propVacant']);
                let resultsArray = sortedArray.map(p => p.propObject).reverse();
                resultsArray.forEach((item) => {
                    document.querySelector('.properties-wrapper .list-properties').appendChild(item);
                });

                let listDisplay = document.querySelector('.properties.list-display');
                let listOffset = listDisplay.querySelector('.wrapper').getBoundingClientRect().top;
                window.addEventListener('scroll', function() {
                    if(listDisplay != undefined) {
                        if((scrollY > (listOffset - header.offsetHeight)) && window.innerWidth >= 1400) {
                            listDisplay.querySelector('.left-content .image').style.top = (scrollY - listOffset + header.offsetHeight) + 'px';
                            listDisplay.querySelector('.left-content .image').style.height = 'calc(100% - ' + (scrollY - listOffset + header.offsetHeight) + 'px)';
                        }
                        else if(window.innerWidth >= 1400) {
                            listDisplay.querySelector('.left-content .image').style.top = 0 + 'px';
                            listDisplay.querySelector('.left-content .image').style.height = '100%';
                        }
                    }
                });

                if(window.innerWidth < 600) {
                    footer.style.display = 'block';
                }
                else {
                    footer.style.display = 'flex';
                }
            }
            else if(slug == 'kart') {
                if(window.innerWidth >= 900) {
                    setTimeout(function() {
                        document.querySelector('#content-section').style.marginTop = 230 + 'px';
                        document.querySelector('#content-section').style.marginTop = header.offsetHeight + 'px';
                        header.classList.remove('scrolledPast');
                    }, 50);
                }
                if(window.innerWidth >= 1400) {
                    setTimeout(function() {
                        document.querySelector('#content-section').style.marginTop = 211 + 'px';
                    }, 55);                
                }
                document.querySelector('.filters').classList.remove('sticky');
                header.classList.remove('sticky');
                header.classList.remove('min-header');

                document.querySelector('section.properties').classList.add('map-display');
                initMap();
            }
            else {
                scrolledPastHeader = false;
                header.classList.remove('animate');
                header.classList.remove('min-header');
                let selectedType = header.querySelector('.property-filter .select-input .select-options li.selected a').getAttribute('data-slug');
                let selectedProps;
                if(selectedType == 'alle' && window.innerWidth >= 900) {
                    let tiles = document.querySelectorAll('.properties .tiles .tile');
                    selectedProps = tiles;
                    selectedProps.forEach((tile) => {
                        tile.style.display = 'grid';
                    });
                }
                else if(selectedType == 'alle') {
                    selectedProps = properties;
                }
                else {
                    selectedProps = document.querySelectorAll('.properties .tease-property.' + selectedType);
                    selectedProps.forEach((property) => {
                        property.style.display = 'block';
                        property.classList.add('displayed');
                    });
                }
                tilesContainer.scrollLeft = tilesContainerHeight/2 - tileHeight/6 - 13.33;
                tilesContainer.scrollTop = tileHeight + 20;
                tilesContainer.style.overflow = 'hidden';
            }
        });
    });

    // Metadata sorting
    propertySorting.querySelectorAll('a').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            let slug = item.getAttribute('data-slug');
            let sortedArray;
            if(slug == 'propAreal' || slug == 'propVacant') {
                sortedArray = [...newArray].sort((a, b) => a[slug] - b[slug]);
            }
            else {
                sortedArray = [...newArray].sort((a, b) => a[slug].localeCompare(b[slug]));
            }
            let resultsArray;
            if(item.classList.contains('active') && item.classList.contains('asc')) {
                item.classList.remove('asc');
                item.classList.add('desc');
                resultsArray = sortedArray.map(p => p.propObject).reverse();
            }
            else if(item.classList.contains('active') && item.classList.contains('desc')) {
                item.classList.remove('desc');
                item.classList.add('asc'); 
                resultsArray = sortedArray.map(p => p.propObject);
            }
            else {
                document.querySelector('.properties .property-sorting a.active').classList.remove('desc');
                document.querySelector('.properties .property-sorting a.active').classList.remove('asc');
                document.querySelector('.properties .property-sorting a.active').classList.remove('active');
                item.classList.add('active');
                item.classList.add('asc');
                resultsArray = sortedArray.map(p => p.propObject);
            }
            resultsArray.forEach((item) => {
                document.querySelector('.properties-wrapper .list-properties').appendChild(item);
            });
        });
    });

    // List display hover function
    function setImage() {
        properties.forEach((property) => {
            let propImageThumbnail = property.getAttribute('data-src');
            let propImageThumbnailMobile = property.getAttribute('data-mobile-src');
            let propImageSquare = property.getAttribute('data-square');
            let propImageLandscape = property.getAttribute('data-landscape');
            let propImages = [propImageThumbnail, propImageThumbnailMobile, propImageSquare, propImageLandscape];
            let randomImage = propImages[Math.floor((Math.random()*propImages.length))];
            property.addEventListener('mouseenter', (e) => {
                leftContent.querySelector('.image').style.backgroundImage = "url('" + randomImage + "')";
            });
            property.addEventListener('mouseleave', (e) => {
                leftContent.querySelector('.image').style.backgroundImage = "unset";
            });
        });
    }

    function filterProperties(searchText) {
        let shownProperties = document.querySelectorAll('.properties .tease-property');
        let selectedType = header.querySelector('.property-filter .select-input .select-options li.selected a').getAttribute('data-slug');
        shownProperties.forEach((property) => {
            property.style.display = 'none';
            property.classList.remove('displayed');
        });
        if(selectedType != 'alle') {
            shownProperties = document.querySelectorAll('.properties .tease-property.' + selectedType);
        }
        shownProperties.forEach((property) => {
            let address = property.getAttribute('data-address');
            if(searchText != '') {
                property.style.display = 'none';
                property.classList.remove('displayed');
                if (address.toLowerCase().indexOf(searchText) !== -1) {
                    property.style.display = 'block';
                    property.classList.add('displayed');
                }
            }
            else {
                property.style.display = 'block';
                property.classList.add('displayed');
            }
        });
    }

    const searchInput = document.querySelector('#properties-search');
    searchInput.addEventListener('keyup', (e) => {
        let searchText = e.target.value.toLowerCase();
        filterProperties(searchText);
    });

    document.addEventListener('click', event => {
        const isClickInside = searchInput.contains(event.target);
        if (!isClickInside) {
            if(searchInput.value.length == 0) {
                document.querySelector('#search-input').classList.remove('expand');
            }
        }
        else {
            document.querySelector('#search-input').classList.add('expand');
        }
    })
    
    // Property category click event
    let selectOptions = document.querySelector('.property-filter .select-input .select-options');
    selectOptions.querySelectorAll('a').forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            let slug = item.getAttribute('data-slug');
            if(window.innerWidth >= 900) {
                getPropertiesTile(slug);    
            }
            else {
                filterProperties('');
            }
        });
    });
}

function getPropertiesTile(type) {
    if((!document.querySelector('.properties').classList.contains('list-display')) && !(document.querySelector('.properties').classList.contains('map-display'))) {
        document.querySelector('.properties-wrapper').style.transition = 'opacity 0.3s ease';
        document.querySelector('.properties-wrapper').style.opacity = 0;
        document.querySelector('.properties .loading-spinner').style.display = 'flex';
    }
    let data = new FormData();
    data.append('action', 'get_properties_tile');
    data.append('type', type);

    $.ajax({
        url: westco_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (data) {
            console.error(data);
        },
        complete: function (data) {
            document.querySelector('.properties-wrapper .tiles').innerHTML = '';
            document.querySelector('.properties-wrapper .tiles').innerHTML = data.responseText;
            propertyEvents();
    
            let images = document.querySelectorAll('img.lazy');
            intersectionObserve(images, 0, 0, (image, intersectionRatio) => {
                (function(el) {
                    if (intersectionRatio > 0) {
                        if (el.classList && el.classList.contains('loaded')) {
                            return;
                        }
                        el.onload = function() {
                            if(!el.classList.contains('show')) {
                                el.classList.add('show');
                            }
                        }
                        el.src = el.getAttribute('data-src');
                        el.classList.add('loaded');  
                    } 
                })(image);
            });
            tilesContainer.scrollLeft = startPosX;
            tilesContainer.scrollTop = startPosY;
            document.querySelector('.properties-wrapper').style.opacity = 1;
            document.querySelector('.properties .loading-spinner').style.display = 'none';
        }
    });
}

$(document).on("dragstart", function() {
    return false;
});